<template>
    <v-app class="top">
        <!-- <div class="topBg"></div> -->
        <v-main class="main">
            <v-container :id="containerId" class="content">
                <router-view :key="key"></router-view>
            </v-container>
        </v-main>

    </v-app>
</template>

<script>
export default {
    name: 'MyLayout',
    components: {

    },
    data: function () {
        return {
            drawerOpened: false,
            containerId: 'container'
        };
    },

    computed: {
        key() {
            return this.$route.name !== undefined ?
                this.$route.name + new Date() :
                this.$route + new Date();
        },

    },

};
</script>

<style lang="scss" scoped>
.top {
    // background-image: url('../assets/bg.jpg');
    // background-repeat: no-repeat;
    // background-size: 150px 80px;
    // background-size: 100% 100%;
    // background-attachment: fixed;
    // position: relative;
    background-color: #b6328a;

    // .topBg {
    //     background-color: #1e0826;
    //     height: 75px;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    // }

}

.content {
    margin: 0 ;
    // margin-top: 0px;
    padding: 0;
    width:100%;
    min-height: 550px;

}
@media (min-width: 900px)
{
.container {
    max-width: 1920px;
}
}


::-webkit-scrollbar {
    display: none;
  }
</style>


