<template>
  <div class="Home">
    <div class="hearder d-flex flex-row justify-space-between align-center">
      <div class="Title">Interchain transform</div>
    </div>
    <div class="btn">
      <button class="wallet" @click="connectWallet">Connect Wallet</button>
      <div class="des mt-5">Asset conversion requires linking to the MetaMask wallet. Only Uptick WASM requires linking the Keplr wallet when creating NFTs. We will connect you at the same time.</div>
      <div class="install">If you haven't installed the wallet plugin yet, click here to install it:</div>
      <div class="footer d-flex flex-row mt-4">
        <button class="metamask" @click="installMetaMask">MetaMask</button>
        <button class="keplr ml-5" @click="installKeplr">Keplr Wallet</button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  
  getkeplrUptickAddress,
  initWallet,
} from "../../keplr/index";

import { wallet as evmWallet } from "@/metaMask";
import { getMatamaskWeb3 } from "@/metaMask/wallet";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {},
  data() {
    return {

    }

  },
  filters: {

  },
  async mounted() {

    initWallet();


  },
  methods: {
    installMetaMask(){
      window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn','_blank')
    },
    installKeplr(){
      window.open('https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?utm_source=chrome-ntp-icon','_blank')
    },
    async connectWallet() {

      // Iris Address
      // let account = await getkeplrIrisAddress();
      let evmAccount = await getMatamaskWeb3();

      // this.$store.commit("SET_DID", account.toLowerCase());
      // uptick Address
      let uptickAccount = await getkeplrUptickAddress();
      console.log("evmAccount",evmAccount,uptickAccount);
      this.$store.commit("SET_UPTICK_DID", uptickAccount.toLowerCase());
      if ( uptickAccount) {
        this.$router.push({ name: "evm" });
      }
    },

    // async getMatamaskWeb3(){
    //   let account = await evmWallet().getAccountInfo();
    //   let address = await account.getAddress();
    //   return address;
    // },
  },

}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.hearder {

  margin:33px 60px;

  .Title {

    font-family: AvenirNext-Bold;
	font-size: 32px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
	color: #ffffff;

    span {
      color: #ed0091;
    }
  }

}


.btn {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wallet {
    width: 351px;
	height: 61px;
	background-color: #ffffff;
	border-radius: 30px;
  font-family: AvenirNext-Bold;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ed0091;
  }
  .des{
    width: 396px;
	font-family: AvenirNext-Regular;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 0px;
	color: #ffffff;
  text-align: center;
  }
  .install{
    margin-top: 72px;

	font-family: AvenirNext-Regular;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 0px;
	color: #ffa7dd;
  }
  .footer{
    .metamask{
      display: flex;
    align-items: center;
    justify-content: center;
      width: 158px;
	height: 43px;
	background-color: #c55ba1;
	border-radius: 21px;
  font-family: AvenirNext-Medium;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
    }
    .metamask::before{
      content: '';
      background-image: url(../../assets/logo_metamask.png);
      width: 32px;
            height: 32px;
            background-size: 100%;
            display: inline-block;
        margin-right: 12px;

    }
  .keplr{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
	height: 43px;
	background-color: #c55ba1;
	border-radius: 21px;
  font-family: AvenirNext-Medium;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
  }
  .keplr::before{
      content: '';
      background-image: url(../../assets/logo_keplr.png);
      width: 32px;
            height: 32px;
            background-size: 100%;
            display: inline-block;
        margin-right: 12px;

    }
  }
}
</style>
